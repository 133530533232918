<template>
  <div class="selectMallCoupon">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>优惠券列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span>名称：</span>
      <el-input
        v-model="formData.name"
        placeholder="请输入优惠券名称"
        style="width:250px"
      ></el-input>
      <span>状态：</span>
      <el-select
        v-model="formData.status"
        clearable
        @clear="onClear(1)"
        placeholder="请选择"
      >
        <el-option :key="-1" label="全部" :value="undefined" />
        <el-option :key="0" label="上架" :value="0" />
        <el-option :key="1" label="下架" :value="1" />
      </el-select>
      <span>有效方式：</span>
      <el-select
        v-model="formData.useWay"
        clearable
        @clear="onClear(1)"
        placeholder="请选择"
      >
        <el-option :key="-1" label="全部" :value="undefined" />
        <el-option :key="0" label="天数" :value="0" />
        <el-option :key="1" label="期限" :value="1" />
      </el-select>
      <el-button type="primary" class="btn" @click="getsblist()"
        >查询</el-button
      >
      <el-button
        type="primary"
        class="add"@click="addVisible = true"
        v-if="
          $store.state.powerList.indexOf('distribution:profit:list:add')!==-1
        "
      >添加优惠券
      </el-button>
    </div>

    <el-table
      border
      ref="multipleTable":data="tableData"
      tooltip-effect="dark"
      style="margin-top:40px; width:98%"
    >
      <el-table-column label="ID" prop="coupon_id" width="50"></el-table-column>
      <el-table-column label="排序" prop="coupon_sort" width="130">
        <template slot-scope="{ row }">
          <el-input-number
            type="number"
            :class="{ borderRed:row.coupon_sort==''||row.coupon_sort < 0 }"
            v-if="row.couponSortInputVisible"
            v-model="row.coupon_sort"@keyup.enter.native="handleInputConfirm(row, 1)"@blur="handleInputConfirm(row, 1)"
            :step="1":min="0"
            v-focus="{cls:'el-input-number',tag:'input',foc:row.couponSortInputVisible,}"
            size="mini"style="width:110px"
          >
          </el-input-number><el-tooltip v-else
            class="item"
            effect="dark"
            content="点击点击修改"
            placement="top"
            :enterable="false"
          >
            <el-button
              class="button-new-tag unset"size="small"@click="onChangeSort(row)"
              >{{row.coupon_sort}}</el-button
            >
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="coupon_name"></el-table-column>
      <el-table-column
        label="价格"
        prop="coupon_price"
        width="100"
      ></el-table-column>
      <el-table-column
        label="可领数量"
        prop="coupon_count"
        width="80"
      ></el-table-column>
      <el-table-column
        label="满减金额"
        prop="coupon_end_price"
      ></el-table-column>
      <el-table-column label="有效方式" prop="coupon_end_price">
        <template slot-scope="{ row }">{{row.coupon_use_way==0?"期限" :"天数"
        }}</template>
      </el-table-column>
      <el-table-column label="有效时间">
        <template slot-scope="{ row }">
          <div v-if="row.coupon_use_way==0">
            {{row.coupon_start_time }}
            <br />
            ~
            <br />
            {{ row.coupon_end_time}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="有效天数" prop="coupon_use_days">
        <template slot-scope="{ row }">
          <div v-if="row.coupon_use_way==1">
            {{ row.coupon_use_days }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="使用类型">
        <template slot-scope="{ row }">
          {{ row.coupon_use_scope==0?"通用" :"指定商品" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220px">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="toModify(row, 0)"
            v-if="row.coupon_status==1"
            >上架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="toModify(row, 1)"
            v-else-if="row.coupon_status==0"
            >下架</el-button
          >
          <el-button size="mini" type="primary" @click="toShowEdit(row)"
            >编辑</el-button
          >
          <el-button size="mini" type="infor" @click="toDelete(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formData.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="formData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="formData.total"
    >
    </el-pagination>

    <el-dialog
      :title="prodtitle"
      @close="onClose"
      :visible.sync="addVisible"
      width="50%"
    >
      <el-form
        :rules="rules"
        :model="addForm"
        ref="ruleForm"
        label-width="100px"
        class="grade_list"
        style="padding-right:50px"
      >
        <el-form-item label="排序" prop="couponSort">
          <el-input-number
            v-model="addForm.couponSort"
            :min="0"
            :step="1"
            :precision="0"
            placeholder="请输入排序"
            style="width:200px"
          />
        </el-form-item>
        <el-form-item label="	优惠券名称" prop="couponName">
          <el-input
            placeholder="请输入优惠券名称"
            v-model="addForm.couponName"
          />
        </el-form-item>
        <el-form-item label="	优惠券价格" prop="couponPrice">
          <el-input-number
            v-model="addForm.couponPrice"
            :min="0"
            :step="1"
            :precision="0"
            placeholder="请输入优惠券价格"
            style="width:200px"
          />
        </el-form-item>
        <el-form-item label="可领数量" prop="couponCount">
          <el-input-number
            v-model="addForm.couponCount"
            :min="1"
            :precision="0"
            placeholder="请输入每人可领数量"
            style="width:200px"
          />
        </el-form-item>
        <el-form-item label="满减金额" prop="endPrice">
          <el-input-number
            v-model="addForm.endPrice"
            :min="0"
            :step="1"
            :precision="0"
            placeholder="请输入满减金额"
            style="width:200px"
          />
        </el-form-item>
        <el-form-item label="有效方式" prop="useWay">
          <el-select
            v-model="addForm.useWay"
            clearable
            placeholder="请选择有效方式"
          >
            <el-option :key="0" label="期限" :value="0" />
            <el-option :key="1" label="天数" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item
          label="有效时间"
          v-if="addForm.useWay==0"
          prop="addDate"
        >
          <el-date-picker
            v-model="addForm.addDate"
            @change="onChangeDate"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="有效天数"
          v-if="addForm.useWay===1"
          prop="useDays"
        >
          <el-input-number
            v-model="addForm.useDays"
            :min="0"
            :step="1"
            :precision="0"
            placeholder="请输入有效天数"
            style="width:200px"
          />
        </el-form-item>
        <el-form-item label="使用范围" prop="useScope">
          <el-select
            v-model="addForm.useScope"
            clearable
            placeholder="请选择有效方式"
          >
            <el-option :key="0" label="通用" :value="0" />
            <el-option :key="1" label="商品" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item
          label="商品ID"
          v-if="addForm.useScope==1"
          prop="prodIds"
        >
          <el-input
            v-model="addForm.prodIds"
            disabled
            placeholder="请选择商品"
            style="width:200px"
          />
          <el-button
            type="primary"
            @click="prodVisible = true"
            style="margin-left:20px"
          >
            添加商品
          </el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" value-format @click="toAddCoupon('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="添加商品" :visible.sync="prodVisible" width="1100px">
      <div class="search_box" style="display:flex; margin-top:0">
        <span>商品名称：</span>
        <el-input
          v-model="prodForm.prodName"
          size="mini"
          placeholder="请输入商品名称"
          style="width:200px"
        ></el-input>
        <span>商品状态:</span>
        <el-select
          v-model.trim="prodForm.status"
          size="mini"
          style="width:100px; margin:0 10px 0 10px"
        >
          <el-option :key="1" label="上架" :value="1"></el-option>
          <el-option :key="2" label="下架" :value="2"></el-option>
          <el-option :key="3" label="删除" :value="3"></el-option>
        </el-select>
        <span>支付方式:</span>
        <el-select
          v-model.trim="prodForm.payWay"
          size="mini"
          style="width:150px; margin:0 10px 0 10px"
        >
          <el-option :key="3" label="积分+现金" :value="3"></el-option>
          <el-option :key="1" label="积分" :value="1"></el-option>
          <el-option :key="2" label="现金" :value="2"></el-option>
        </el-select>
        <el-button type="primary" size="mini" @click="onSet()">重置</el-button>
        <el-button type="primary" size="mini" @click="getProdTableList()"
          >查找</el-button
        >
      </div>
      <el-table
        :data="prodTableData"
        style="width:100%; margin-top:20px"
        size="mini"
        border
        @selection-change="prodSelectionChange"
      >
        <el-table-column type="selection" align="center" width="50">
        </el-table-column>
        <el-table-column
          align="center"
          prop="prodId"
          label="商品ID"
          width="70"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="prodName"
          label="商品名称"
          width="300"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="prodHomeImg"
          label="商品封面"
          width="100"
        >
          <template slot-scope="{ row }">
            <img :src="row.prodHomeImg" width="80" height="80" />
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="prodPrice"
          label="商品价格"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="prodRepertory"
          label="商品库存"
          width="width"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="prodSellCount"
          label="商品销量"
          width="width"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="prodStatus"
          label="商品状态"
          width="width"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="prodType"
          label="商品类型"
          width="width"
        ></el-table-column>

        <el-table-column align="center" label="操作" width="80">
          <template slot-scope="{ row }">
            <el-button
              type="primary"
              size="mini"
              v-if="prodIdsArr.indexOf(row.prodId)===-1"
              @click="selectProd([row])"
              >添加</el-button
            >
            <el-button
              size="mini"
              v-if="prodIdsArr.indexOf(row.prodId)!==-1"
              @click="unselectProd([row])"
              >取消</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="prodForm.currentPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="prodForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="prodForm.total"
          :small="true"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="prodVisible = false">取 消</el-button>
        <el-button type="primary" @click="selectAllProd()">批量添加</el-button>
        <!-- <el-button type="primary" @click="prodVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { productList } from "../../api/selectMall";
import { couponAdd, couponList, couponModify } from "../../api/selectMall.js";
export default {
  name:"selectMallCoupon",
  data() {
    return {
      prodtitle:"添加优惠券",
      addVisible:false,
      editVisible:false,
      couponId:"",
      prodVisible:false,
      addDate:"",
      addForm:{
        couponSort:"",
        couponName:"",
        couponPrice:"",
        couponCount:"",
        endPrice:"",
        useWay:"",
        startTime:"",
        endTime:"",
        useDays:"",
        useScope:"",
        prodIds:"",
      },
      prodIdsArr:[],
      editForm:{},

      tableData:[],
      formData:{
        name:"", // 商品名称
        status:undefined, // 有效方式0天数 1期限
        useWay:undefined, // 状态0下架 1上架
        total:0,
        pageSize:10,
        currentPage:1,
      },

      prodTableData:[],
      storeList:[],
      prodSelect:[],
      prodForm:{
        prodName:"",
        storeId:undefined,
        payWay:undefined,
        status:undefined,
        total:0,
        pageSize:10,
        currentPage:1,
      },
      rules:{
        couponSort:[
          { required:true, message:"请输入排序", trigger:"blur" },
        ],
        couponName:[
          { required:true, message:"请输入优惠券名称", trigger:"blur" },
          {
            min:3,
            max:25,
            message:"长度在 3 到 25 个字符",
            trigger:"blur",
          },
        ],
        couponPrice:[
          { required:true, message:"请输入优惠券价格", trigger:"blur" },
        ],
        couponCount:[
          { required:true, message:"请输入可领数量", trigger:"blur" },
        ],
        endPrice:[
          { required:true, message:"请输入满减金额", trigger:"blur" },
        ],
        useWay:[
          { required:true, message:"请选择优惠券有效方式", trigger:"blur" },
        ],
        addDate:[
          {
            required:true,
            message:"请选择优惠券有效期限",
            trigger:"change",
          },
        ],
        useDays:[
          { required:true, message:"请选择优惠券有效天数", trigger:"blur" },
        ],
        useScope:[
          {
            required:true,
            message:"请选择优惠券使用范围",
            trigger:"change",
          },
        ],
        prodIds:[{ required:true, message:"请选择商品", trigger:"change" }],
      },
    };
  },
  created() {
    this.getsblist();
    this.getProdTableList();
  },
  watch:{
    prodVisible() {
      if (this.prodVisible) {
        this.getProdTableList();
      }
    },
  },
  methods:{
    async getsblist() {
      const { data } = await couponList(this.formData);
      data.list.forEach((item) => {
        item.couponSortInputVisible = false;
      });
      this.tableData = data.list;
      this.formData.total = data.pagination.total;
      this.formData.pageSize = data.pagination.pageSize;
      this.formData.currentPage = data.pagination.current;
    },
    async getProdTableList() {
      const { data } = await productList(this.prodForm);
      this.prodTableData = data.list;
      this.prodForm.total = data.pagination.total;
      this.prodForm.pageSize = data.pagination.pageSize;
      this.prodForm.currentPage = data.pagination.current;
    }, 
    async handleInputConfirm(row) {
      console.log(row);
      if ((!row.coupon_sort&&row.coupon_sort != 0)||row.coupon_sort < 0) {
        return this.$message.error("请输入正数!");
      }
      let { data } = await couponModify({
        couponSort:Number(row.coupon_sort),
        couponId:row.coupon_id,
      });
      if (data.code==0) {
        this.$message.success("修改成功");
        row.couponSortInputVisible = false;
        this.getsblist();
      } else {
        this.$message.error("修改失败");
      }
    }, 
    onChangeSort(row) {
      row.couponSortInputVisible = true;
    }, 
    onChangeDate(value) {
      console.log(value);
      this.addForm.startTime = value[0];
      this.addForm.endTime = value[1];
    }, 
    toShowEdit(row) {
      this.prodtitle = "编辑优惠券";
      for (var k in this.addForm) {
        this.addForm[k] = "";
      }
      this.couponId = row.coupon_id;
      let editForm = {};
      editForm.couponName = row.coupon_name;
      editForm.couponSort = row.coupon_sort;
      editForm.couponPrice = row.coupon_price;
      editForm.couponCount = row.coupon_count;
      editForm.endPrice = row.coupon_end_price;
      editForm.useWay = row.coupon_use_way;
      editForm.startTime = row.coupon_start_time;
      editForm.endTime = row.coupon_end_time;
      editForm.useDays = row.coupon_use_days;
      editForm.useScope = row.coupon_use_scope;
      editForm.prodIds = row.coupon_prod_ids;
      editForm.addDate =
        row.coupon_use_way==0
         ?[row.coupon_start_time, row.coupon_end_time]
          :"";
      this.addForm = editForm;

      this.prodIdsArr = row.coupon_prod_ids.split(",");
      this.prodIdsArr = this.prodIdsArr.map((item) => {
        return Number(item);
      });
      this.addVisible = true;
    }, 
    async toModify(row, status) {
      let str = row.coupon_status==0?"下架" :"上架";
      this.$confirm(`确认执行${str}操作吗?`, "提示", {
        confirmBottomText:"确认",
        cancelButtonText:"取消",
        type:"warning",
      })
        .then(async () => {
          const { data } = await couponModify({
            couponId:row.coupon_id,
            status,
          });
          if (data.code==0) {
            this.getsblist();
            this.$message.success(str + "成功");
          } else {
            this.$message.error(str + "失败");
          }
        })
        .catch(() => {
          this.$message({ type:"info", message:"已取消操作" });
        });
    }, 
    async toDelete(row) {
      this.$confirm("确认执行删除操作吗?", "提示", {
        confirmBottomText:"确认",
        cancelButtonText:"取消",
        type:"warning",
      })
        .then(async () => {
          const { data } = await couponModify({
            couponId:row.coupon_id,
            ds:1,
          });
          if (data.code==0) {
            this.$message.success("删除成功");
            this.getsblist();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch(() => {
          this.$message({ type:"info", message:"已取消操作" });
        });
    }, 
    async toAddCoupon(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.toUpLoadForm();
        } else {
          this.$message({
            type:"error",
            message:"请把数据填写完整",
          });
          return false;
        }
      });
    },
    async toUpLoadForm() {
      let data = {};
      let setData = this.addForm;
      if (setData.useWay==0&&setData.addDate[0]) {
        setData.startTime = setData.addDate[0];
        setData.endTime = setData.addDate[1];
      }
      if (this.couponId) {
        data = await couponModify({
          couponId:this.couponId,
          ...setData,
        });
      } else {
        data = await couponAdd({
          ...setData,
        });
      }
      data = data.data;
      if (data.code==0) {
        this.addVisible = false;
        this.getsblist();
        this.$message({
          type:"success",
          message:data.msg,
        });
      } else {
        this.$message({
          type:"error",
          message:data.msg,
        });
      }
    }, 
    onSet() {
      this.prodForm.prodName = "";
      this.prodForm.payWay = null;
      this.prodForm.status = null;
    }, 
    onClose() {
      for (const key in this.addForm) {
        this.addForm[key] = "";
      }
      this.prodIdsArr = [];
      this.prodtitle = "添加优惠券";
    }, 
    unselectProd(prodList) {
      this.prodIdsArr = this.prodIdsArr.filter((item) => {
        return item!==prodList[0].prodId;
      });
      this.prodVisible = false;
      this.addForm.prodIds = this.prodIdsArr.join(",");
    }, 
    selectAllProd() {
      if (this.prodSelect.length < 1) {
        return this.$message.error("请先选择商品!");
      }
      let list2 = this.prodSelect.map((item) => {
        return item.prodId;
      });
      this.prodIdsArr = this.prodIdsArr.concat(list2);
      this.prodIdsArr = Array.from(new Set(this.prodIdsArr));
      this.addForm.prodIds = this.prodIdsArr.join(","); 
    }, 
    selectProd(prodList) { 
      this.prodIdsArr.push(prodList[0].prodId);
      this.addForm.prodIds = this.prodIdsArr.join(","); 
    },

    prodSelectionChange(arr) {
      this.prodSelect = arr||[];
    },
    handleSizeChange2(num) {
      this.prodForm.pageSize = num;
      this.getProdTableList();
    },
    handleCurrentChange2(num) {
      this.prodForm.currentPage = num;
      this.getProdTableList();
    },
    handleSelectionChange(arr) {
      this.multipleSelection = arr;
    },
    handleSizeChange(num) {
      this.formData.pageSize = num;
      this.getsblist();
    },
    handleCurrentChange(num) {
      this.formData.currentPage = num;
      this.getsblist();
    },
  },
  directives:{
    focus:{
      inserted:function (el, option) {
        var defClass = "el-input",
          defTag = "input";
        var value = option.value||true;
        if (typeof value==="boolean")
          value = {
            cls:defClass,
            tag:defTag,
            foc:value,
          };
        else
          value = {
            cls:value.cls||defClass,
            tag:value.tag||defTag,
            foc:value.foc||false,
          };
        if (el.classList.contains(value.cls)&&value.foc)
          el.getElementsByTagName(value.tag)[0].focus();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.selectMallCoupon {
  .el-breadcrumb {
    height:35px;
    border-bottom:1px solid rgb(192, 191, 191);
  }

  .add { 
  }

  .el-table { 
  }

  .search_box {
    margin-top:50px;
    display:flex;
    align-items:center;
    flex-wrap:wrap;

    span {
      margin-left:20px;
      font-size:15px;
      color:#333333;

      &:first-child {
        margin-left:0;
      }
    }

    .btn {
      margin-left:20px;
    }
  }

  .el-pagination {
    display:flex;
    justify-content:flex-end;
    margin-top:20px;
  }
}
</style>
